<template>
  <v-main class="batches">
    <div class="app-container pt-10 pb-3">
      <section
        class="batches__header d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="batches__text">
          <h1 class="text-h4 font-weight-bold">Audit List</h1>
        </div>

        <div class="d-flex justify-start align-center">
          <a
            v-if="!isMobile && isAdmin"
            class="cases__header__delete-btn d-flex align-center justify-center"
            :class="{
              'cases__header__delete-btn--disabled': !selectedBatches.length,
            }"
            @click="onAuditRemove"
          >
            <v-icon color="black">mdi-delete</v-icon>
          </a>
          <a
            v-if="!isMobile"
            class="batches__header__filter-btn d-flex align-center justify-center"
            :class="{ 'batches__header__filter-btn--active': isFilterActive }"
            @click="onFilterClick"
          >
            <v-icon color="black">mdi-filter-variant</v-icon>
          </a>
          <v-badge
            v-else
            :value="filterBadgeContent"
            :content="filterBadgeContent"
            offset-x="15"
            offset-y="20"
            color="primary"
          >
            <a
              class="batches__header__filter-btn d-flex align-center justify-center"
              @click="onFilterClick"
            >
              <v-icon color="black">mdi-filter-variant</v-icon>
            </a>
          </v-badge>
        </div>
      </section>
      <BatchesQuickFilters @change="handleQuckFilters($event)" />
      <v-skeleton-loader
        v-if="firstLoading"
        :loading="loading"
        type="table"
        min-width="100%"
      />
      <section class="batches__table">
        <v-data-table
          v-show="!firstLoading"
          v-model="selectedBatches"
          v-bind="isAdmin ? { showSelect: true } : {}"
          :headers="headers"
          :items="batches"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalBatches"
          :item-class="itemRowBackground"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          :mobile-breakpoint="0"
          multi-sort
          item-key="_id"
          @update:options="handleTableUpdate"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-if="isFilterActive && !isMobile" v-slot:top>
            <v-row class="d-lfex align-center mb-3">
              <v-col class="d-flex" cols="3">
                <search-input
                  :value="filters.name"
                  label="Search Audit name"
                  @change="handleFilterChange($event, 'name')"
                />
              </v-col>

              <v-col class="d-flex" cols="2">
                <v-select
                  :value="filters.type"
                  :items="batchTypes"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Audit type"
                  hide-details
                  clearable
                  outlined
                  @change="handleFilterChange($event, 'type')"
                />
              </v-col>

              <v-col class="d-flex" cols="4">
                <ClinicalServicesMultiselect
                  :services="filters.clinicalServiceId"
                  ref="servicesInput"
                  @change="handleFilterChange($event, 'clinicalServiceId')"
                />
              </v-col>

              <v-col class="d-flex" cols="2">
                <v-select
                  v-model="filters.status"
                  :items="batchStatuses"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Audit status"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'status')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <time-data-picker
                  ref-name="menu2"
                  :date="filters.endDate"
                  label="End Date"
                  @change="handleFilterChange($event, 'endDate')"
                />
              </v-col>

              <v-col class="d-flex justify-center ml-12" cols="1">
                <CancelButton @click="onFilterCancel" />
              </v-col>
              <v-col class="d-flex justify-center ml-16" cols="1">
                <ApplyButton @click="handleApplyBotton" />
              </v-col>
            </v-row>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.endDate="{ item }">
            <span>{{
              item.endDate ? dateToLocalString(item.endDate, false) : "-"
            }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.createdAt="{ item }">
            <span>{{ dateToLocalString(item.createdAt, false) }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.status="{ item }">
            <v-chip outlined>
              {{ item.status }}
            </v-chip>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.clinicalServiceId.name="{ item }">
            {{ get(item, "clinicalServiceId.name", "-") }}
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.type="{ item }">
            {{
              item.type === BatchTypes.general
                ? BatchTypes.specialAudit
                : item.type
            }}
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.template="{ item }">
            {{ get(item, "auditTemplateId.name", "-") }}
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.createdBy="{ item }">
            {{ get(item, "createdBy.name", "-") }}
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.casesAmount="{ item }">
            {{ getCasesAmount(item) }}
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.report.auditsGrade.score="{ item }">
            {{ getAuditScore(item) }}
          </template>
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.review="{ item }">
            <v-btn
              v-if="item.status === BatchStatuses.closed"
              color="primary"
              rounded
              outlined
              normal
              variant="elevated"
              class="ml-2"
              @click="goToBatchStatistic(item._id)"
            >
              Statistic
            </v-btn>
            <v-btn
              v-else
              color="primary"
              rounded
              outlined
              normal
              variant="elevated"
              class="ml-2"
              @click="handleReviewBtnClick(item._id)"
            >
              Review
            </v-btn>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          :value="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        />
      </section>

      <v-dialog v-if="isMobile" v-model="isFilterActive" fullscreen persistent>
        <v-card flat class="batches__filter-menu">
          <div
            class="app-container pb-4 d-flex flex-column justify-space-between"
          >
            <div class="batches__filter-menu__filters pt-10">
              <h1 class="text-h4 font-weight-bold pb-5">Filters</h1>

              <search-input
                :value="filters.name"
                label="Search Batch name"
                @change="handleFilterChange($event, 'name')"
              />
              <br />
              <v-select
                :value="filters.type"
                :items="batchTypes"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Batch type"
                hide-details
                clearable
                outlined
                @change="handleFilterChange($event, 'type')"
              />
              <br />
              <ClinicalServicesMultiselect
                :services="filters.clinicalServiceId"
                ref="servicesInput"
                @change="handleFilterChange($event, 'clinicalServiceId')"
              />
              <br />
              <v-select
                v-model="filters.status"
                :items="batchStatuses"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Batch status"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'status')"
              />
              <br />
              <time-data-picker
                ref-name="menu2"
                :date="filters.endDate"
                label="End Date"
                @change="handleFilterChange($event, 'endDate')"
              />
            </div>

            <div class="batches__filter-menu__controls d-flex flex-column">
              <ApplyButton large class="mb-5" @click="handleApplyBotton" />
              <CancelButton large @click="onFilterCancel" />
            </div>
          </div>
        </v-card>
      </v-dialog>

      <DeleteModal
        title="Delete audits"
        description="Are you sure you want to delete selected audits?"
        :is-opened="deleteBatchModal"
        :loading="deleteBatchesLoading"
        @submit="onDeleteAudit"
        @close="deleteBatchModal = false"
      />
    </div>
  </v-main>
</template>

<script>
import { mapState } from "pinia";
import { get } from "lodash";
import { useUserStore } from "@/stores/user";
import dateToLocalString from "@/helpers/dateToLocalString";
import { getBatches, batchDeleteBatches } from "@/services/batch";
import SearchInput from "@/components/controls/SearchInput.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import DeleteModal from "@/components/common/DeleteModal.vue";
import {
  TableRowsPerPage,
  CasesStatuses,
  BatchTypes,
  BatchStatuses,
} from "@/misc/constants";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";
import BatchesQuickFilters from "@/components/batches/BatchesQuickFilters.vue";

export default {
  name: "BatchesPage",

  components: {
    ClinicalServicesMultiselect,
    SearchInput,
    TimeDataPicker,
    DeleteModal,
    CancelButton,
    ApplyButton,
    BatchesQuickFilters,
  },

  data() {
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    const headers = [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: true,
      },
      {
        text: "Service",
        value: "clinicalServiceId.name",
        sortable: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: true,
      },
      {
        text: "Template",
        value: "template",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Creator",
        value: "createdBy",
        sortable: false,
      },
      {
        text: "Cases Amount",
        value: "casesAmount",
        sortable: false,
      },
      {
        text: "Audit score",
        value: "report.auditsGrade.score",
        sortable: false,
      },
      {
        text: "Create Date",
        value: "createdAt",
      },
      {
        text: "End Date",
        value: "endDate",
      },
      {
        text: "",
        value: "review",
        sortable: false,
      },
    ];

    return {
      loading: true,
      firstLoading: true,
      options: { sortBy: ["createdAt"], sortDesc: [true] },
      totalBatches: null,
      tableFooterOptions,
      isFilterActive: false,
      headers,
      batches: [],
      filters: {
        name: null,
        type: null,
        status: null,
        endDate: null,
        clinicalServiceId: null,
      },
      batchStatuses: [BatchStatuses.closed, BatchStatuses.opened],
      createBatchModal: false,
      selectedBatches: [],
      deleteBatchModal: false,
      deleteBatchesLoading: false,
      BatchStatuses,
      BatchTypes,
      batchTypes: [
        BatchTypes.specialAudit,
        BatchTypes.docReview,
        BatchTypes.general,
      ],
    };
  },

  computed: {
    ...mapState(useUserStore, ["isAdmin", "isAuditor"]),
    isFilterButtonActive() {
      return Object.values(this.filters).some((val) => !!val === true);
    },
    filterBadgeContent() {
      return Object.values(this.filters).reduce(
        (acc, value) => acc + !!value,
        0
      );
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalBatches / this.options.itemsPerPage);
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async created() {
    if (this.$route.query.filters) {
      this.filters = JSON.parse(this.$route.query.filters);
      this.isFilterActive = true;
    }
    // await this.fetchBatches();
  },
  async mounted() {
    if (this.$route.query?.opened) {
      this.isFilterActive = true;
      this.filters.status = BatchStatuses.opened;
    }
  },

  methods: {
    get,
    dateToLocalString,
    async fetchBatches() {
      try {
        this.loading = true;
        const query = this.buildQuery();
        const { data, total } = await getBatches(query);
        this.totalBatches = total;
        this.batches = data;
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }

      queryParams.filters = this.buildFilters();

      return queryParams;
    },
    buildFilters() {
      const filters = { status: [BatchStatuses.opened, BatchStatuses.closed] };
      if (Object.values(this.filters).some((val) => !!val === true)) {
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "endDate") {
              const [first, second] = this.filters[key];
              const startDate = new Date(first);
              const endDate = second
                ? new Date(second)
                : new Date(startDate.getTime() + 86400000);
              filters[key] = { $gte: startDate, $lt: endDate };
            } else if (key === "name") {
              filters[key] = { $regex: this.filters[key], $options: "i" };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
      }
      return JSON.stringify(filters);
    },
    async handleTableUpdate() {
      await this.fetchBatches();
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    onFilterClick() {
      this.isFilterActive = !this.isFilterActive;
    },
    goToBatch({ _id }) {
      this.$router.push(`batches/${_id}`);
    },
    goToBatchStatistic(id) {
      this.$router.push(`batches/${id}/statistic`);
    },
    itemRowBackground(item) {
      return item.status === CasesStatuses.groupReview
        ? "group-review-needed-status"
        : "";
    },
    async onFilterCancel() {
      let params = {
        path: "/batches",
      };
      let from = this.$route.fullPath;
      let to = this.$router.resolve(params).route.fullPath;

      if (from === to) {
        return;
      }

      this.$router.push(params);
    },
    async handleFilterChange(value, key) {
      this.filters[key] = value;
      this.options.page = 1;
    },
    handleReviewBtnClick(id) {
      this.$router.push(`/batches/${id}`);
    },
    onAuditRemove() {
      if (!this.selectedBatches.length) {
        return;
      }
      this.deleteBatchModal = true;
    },
    async onDeleteAudit() {
      if (this.deleteBatchesLoading) return;
      this.deleteBatchesLoading = true;
      const batches = this.selectedBatches.map((el) => el._id);
      try {
        await batchDeleteBatches(batches);
        this.$notify({
          type: "success",
          text: "Audits deleted",
        });
      } catch (err) {
        this.$notify({
          type: "Error",
          text: err?.message || JSON.stringify(err),
        });
      }
      this.deleteBatchModal = false;
      this.deleteBatchesLoading = false;
      this.fetchBatches();
    },
    getCasesAmount(batch) {
      if (batch?.type === BatchTypes.general) return "-";

      const amountOfCaseAudits = batch?.auditIds?.length;
      if (amountOfCaseAudits) return amountOfCaseAudits;
      else return batch?.clinicianBasedCases?.length || "-";
    },
    getAuditScore(batch) {
      if (batch?.status === "Closed") {
        if (batch?.type === BatchTypes.general)
          return batch.report.evaluationResult[0].amount;
        if (batch?.type === BatchTypes.docReview)
          return batch.report.auditsGrade.score;
      }
      return "-";
    },
    handleQuckFilters(data) {
      Object.keys(this.filters).forEach((key) => (this.filters[key] = null));
      Object.keys(data).forEach((key) => {
        this.filters[key] = data[key];
      });
      this.fetchBatches();
    },
    handleApplyBotton() {
      let params = {
        path: "/batches",
        query: { filters: JSON.stringify(this.filters) },
      };
      let from = this.$route.fullPath;
      let to = this.$router.resolve(params).route.fullPath;

      if (from === to) {
        return;
      }

      this.$router.push(params);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.batches {
  .group-review-needed-status {
    background-color: #fff2f1;
  }

  &__header {
    &__filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }

    &__delete-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--disabled {
        cursor: not-allowed !important;
        opacity: 0.2;
      }
    }
  }

  &__filter-menu {
    & .app-container {
      height: 100vh;
      padding-top: #{$headerHeight};
    }
  }
}
</style>
